import { CONSOLE_GREETING } from '../../constants/constants'

const Greeting = () => {
  // eslint-disable-next-line no-console
  console.info(CONSOLE_GREETING)

  return null
}

export default Greeting
